import React from "react";
//import logo from "../res/logo.png"

export default class Footer extends React.Component {
    _mounted;
    constructor(props){
        super(props);
    }

    componentDidMount(){
        this._mounted = true;
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    render(){
        return <div className="footer" id="contacto">
            <div className="row mx-0">
                <div className="col-sm-6 mt-5 px-4">
                    <h1 className="mt-0">CONTÁCTANOS</h1>

                    <p className="mt-3">Tenemos todo lo que necesitas para tus mascotas.
                    Te brindamos información relevante y también te contactamos con veterinarios profesionales</p>

                    <div className="flex-left">
                    <a href="https://www.instagram.com/mismascotasmex/" target="_blank" class="fa fa-instagram"></a>
                   

                   
                    <a href="https://www.facebook.com/mismascotasmex" target="_blank" class="fa fa-facebook ml-1"></a>
                    

                 
                    <a href="https://www.pinterest.com.mx/mismascotasmx" target="_blank" class="fa fa-pinterest"></a>
                    
                    </div>
                </div>

                <div className="col-sm-3 mt-5 px-4">
                    <p><b>Sitios de interés</b></p>

                    <a className="mt-3">Home</a>
                    <a className="mt-3" >Nosotros</a>
                    <a className="mt-3" href="mailto:example@gmail.com">Contáctanos</a>
                </div>

                <div className="col-sm-3 mt-5 px-4">
                    <p><b>Veterinarios</b></p>

                    <a className="mt-3">Regístrate</a>
               
                </div>
            </div>

            <hr className="mt-5"/>

            <div className="flex-sb mt-3">
                <p className="mb-0">(©) Copyright 2020 <a className="special-link">Mismascotas</a></p>

                <a>Términos y condiciones</a>
            </div>
        </div>
    }
}