import React,{lazy,Suspense} from 'react';
import logo from './logo.svg';
import './styles/css/App.css';
import {BrowserRouter as Router, Switch, NavLink, Route, Redirect} from "react-router-dom";
import { Position, Toast, Toaster, Classes} from "@blueprintjs/core";
import Footer from "./components/Footer";
import CircleLoader from "./components/loaders/CircleLoader"

const Pets  = lazy(() => import("./components/Pets"));
const Veterinarians = lazy(() => import("./components/Veterinarians"));
const Admin = lazy(() => import("./components/Admin"));

const key = require("keyboard-shortcut");

class App extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      toasts:[]
    }

    this.toaster = {};
        this.refHandlers = {
            toaster:(ref) => {this.toaster = ref},
        }
  }

  addToast = (message) => {
    this.toaster.show({ message: message});
}


  componentDidMount(){

  }

  componentWillUnmount(){

  }

  render(){
    return <div className="app">
      <Toaster className={Classes.OVERLAY} position={Position.TOP} ref={this.refHandlers.toaster}>
                    {/* "Toasted!" will appear here after clicking button. */}
                    {this.state.toasts.map(toast => <Toast action={{onClick:() => {}, text:"Resend"}} {...toast} />)}
           </Toaster>


           <Suspense fallback={<CircleLoader/>}>
        <Router>
          <Switch>
            <Route exact={true} path="/" render={() => <Redirect to="/mascotas"/>}/>
            <Route exact={true} path="/mascotas" render={() => <Pets addToast={this.addToast}/>}/>
            <Route exact={true} path="/veterinarios" render={() => <Veterinarians addToast={this.addToast}/>}/>
            <Route exact={true} path="/admin" render={() => <Admin addToast={this.addToast}/>}/>
          </Switch>

          <Footer/>
        </Router>
      </Suspense>
    </div>
  }
}
export default App;
